<template>
  <div>
    <div class="tail tailbg" :style="backgroundImage">
      <el-row :span="24">
        <el-col>
          <img :src="tailLogo" class="tailLogo" />
          <el-col class="text-center">
            <span class="numspan">+86</span>
            <el-input
              placeholder="请输入手机号"
              v-model="phone"
              class="tailInput"
            >
            </el-input>
            <el-button type="primary" v-prevent-repeat-click @click="postPhone"
              >我有证书兼职</el-button
            >
          </el-col>
        </el-col>
      </el-row>
    </div>
    <section class="clearfix">
      <div class="tailLeft">
        <p>
          <el-link @click="upgrade">升级套餐</el-link>
          <!-- <el-link href="#" target="_blank">帮助</el-link> -->
          <el-link href="http://www.51yixiang.com/about.html" target="_blank"
            >关于我们</el-link
          >
          <el-link href="http://www.51yixiang.com/talent.html" target="_blank"
            >加入我们</el-link
          >
          <!-- <el-link href="#" target="_blank">代理合作</el-link> -->
          <el-link @click="goNotice">服务条款</el-link>
          <!-- <el-link href="#" target="_blank">法律声明</el-link> -->
        </p>
        <p>
          <span class="beian">
            Copyright © {{ webUrl }}, All Rights Reserved.
          </span>
        </p>
        <p>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            class="beianhao"
            v-if="webUrl == 'www.51chinajob.com'"
          >
            沪ICP备11050821号-5
          </a>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            class="beianhao"
            v-if="webUrl == 'www.51jiansousou.com'"
          >
            沪ICP备17038937号-3
          </a>
        </p>
      </div>
      <div class="tailRight demo-image">
        <div class="block" style="padding-top: 30px;">
          <el-image
            style="width: 100px; height: 100px;padding: 5px;box-sizing: border-box;"
            :src="QRCode01"
          ></el-image>
          <span class="demonstration">{{ QRTitle01 }}</span>
        </div>
        <div class="block">
          <el-image
            style="width: 100px; height: 100px"
            :src="QRCode02"
          ></el-image>
          <span class="demonstration">{{ QRTitle02 }}</span>
        </div>
        <div class="block">
          <el-image
            style="width: 100px; height: 100px"
            :src="QRCode03"
          ></el-image>
          <span class="demonstration">{{ QRTitle03 }}</span>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "tail",
  data() {
    return {
      backgroundImage:
        "background-image:url(" + require("@/assets/img/tailbg.png") + ")",
      tailLogo: require("@/assets/img/taillogo.png"),
      QRCode01: require("@/assets/img/wxapp.png"),
      QRTitle01: "打开微信小程序",
      QRCode02: require("@/assets/img/appdown.png"),
      QRTitle02: "扫码下载APP",
      QRCode03: require("@/assets/img/erweima01.png"),
      QRTitle03: "扫码关注公众号",
      phone: "",
      webUrl: "",
    };
  },
  computed: {
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    postPhone() {
      this.$http
        .post("index/home/havecer", { phone: this.phone })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.$message.success("已提交成功！");
          }
        });
    },
    // 服务条款
    goNotice() {
      let routeUrl = this.$router.resolve({
        name: "Notice",
      });
      window.open(routeUrl.href, "_blank");
    },
    // 升级套餐
    upgrade() {
      if (this.userInfo.uid == undefined) {
        this.$message.warning("您还没有登录，现在前往登录！");
      } else if (this.userInfo.uid != undefined) {
        if (this.userInfo.u_identity == 1) {
          this.$message.warning("您的账号为人才角色，无需升级套餐！");
        } else {
          this.$router.push("/comPage/vipPay");
        }
      }
    },
  },
  mounted() {
    this.webUrl = window.location.host;
  },
};
</script>
<style scoped>
.tail {
  min-width: 1200px;
  padding: 0 20px;
}
.tailbg {
  height: 272px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.tailLogo {
  display: block;
  text-align: center;
  margin: 60px auto 40px;
}
.tailInput {
  width: 400px;
  margin-right: 10px;
}
/deep/ .tailInput .el-input__inner {
  padding-left: 40px;
}
.el-button--primary {
  color: #fff;
  background-color: #0070fa;
  border-color: #0070fa;
}
section p:first-child {
  margin-top: 66px;
  margin-bottom: 24px;
}
section p a.el-link.el-link--default {
  color: #333;
  margin-right: 24px;
}
section p a.el-link.el-link--default:hover {
  color: #409eff;
}
.beian {
  font-size: 14px;
  color: #333;
}
.tailLeft {
  width: 800px;
  float: left;
}
.tailRight {
  width: 400px;
  float: left;
}
.tailRight .block:first-child {
  margin-left: 45px;
}
.block {
  padding: 30px 8px 0px;
  text-align: center;
  border-right: 1px solid #eff2f6;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
.demo-image .demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.numspan {
  position: relative;
  right: -35px;
  z-index: 1;
}
</style>
