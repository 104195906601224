<!--
 * @Description: 登录弹窗
 * @Author: huacong
 * @Date: 2021-07-12 11:12:06
 * @LastEditTime: 2021-07-29 09:48:44
 * @LastEditors: huacong
-->
<template>
  <div>
    <div class="loginBox">
      <el-tabs
        class="tabBox padding-20"
        v-model="loginType"
        @tab-click="switchLoginState"
        v-show="loginBoxShow"
      >
        <el-tab-pane class="tabTitle" label="短信登录" name="first">
          <el-row>
            <el-col class="" :span="10">
              <div class="loginTitle">
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon01" alt="" />
                  </div>
                  <div class="text">
                    <span>品牌实力</span><br />
                    <span>100万+建筑人才首选</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon02" alt="" />
                  </div>
                  <div class="text">
                    <span>在线微聊</span><br />
                    <span>在线开聊更安全便捷</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon03" alt="" />
                  </div>
                  <div class="text">
                    <span>免费服务</span><br />
                    <span>价格咨询、推荐单位</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane class="tabTitle" label="密码登录" name="second">
          <el-row>
            <el-col class="" :span="10">
              <div class="loginTitle">
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon01" alt="" />
                  </div>
                  <div class="text">
                    <span>品牌实力</span><br />
                    <span>100万+建筑人才首选</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon02" alt="" />
                  </div>
                  <div class="text">
                    <span>在线微聊</span><br />
                    <span>在线开聊更安全便捷</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon03" alt="" />
                  </div>
                  <div class="text">
                    <span>免费服务</span><br />
                    <span>价格咨询、推荐单位</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <!-- 登录form -->
        <el-col class="positionBox padding-20" :span="14">
          <div class="formBox">
            <el-form :model="loginInfo">
              <el-form-item>
                <el-input placeholder="请输入手机号" v-model="loginInfo.phone">
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-mobile-phone"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item v-if="loginType != 'first'">
                <el-input
                  placeholder="请输入密码"
                  type="password"
                  v-model="loginInfo.password"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <DragBar></DragBar>
              </el-form-item>
              <!-- 登录验证码 -->
              <el-form-item v-if="loginType == 'first'">
                <el-input placeholder="请输入验证码" v-model="loginInfo.code">
                  <el-button
                    slot="append"
                    type="primary"
                    :disabled="codeBtn"
                    v-prevent-repeat-click
                    v-if="smsTime > 59"
                    @click="gainLoginCode"
                  >
                    获取验证码
                  </el-button>
                  <template slot="append" v-if="smsTime < 60">
                    {{ smsTime }}秒后可重发
                  </template>
                </el-input>
              </el-form-item>
              <el-button
                type="primary"
                class="loginBtn"
                :disabled="codeBtn"
                v-prevent-repeat-click
                @click="goLogin"
              >
                立即登录
              </el-button>
            </el-form>
            <div class="loginConfig">
              <el-checkbox v-model="loginState">记录登录状态</el-checkbox>
              <el-link type="primary" @click="goRegist">注册新用户</el-link>
              <el-link type="primary"></el-link>
            </div>
            <div class="otherLine">
              <span>其他方式登录</span>
            </div>
            <div class="text-center margin-top-20">
              <img
                :src="wechatIcon"
                alt=""
                style="width:36px;"
                @click="changWXLogin"
              />
            </div>
          </div>
        </el-col>
      </el-tabs>
      <!-- 微信登录 -->
      <div class="wxLoginBox" v-show="wxloginBoxShow">
        <div class="text-left backLoginBox">
          <span @click="backLoginBox">&lt;&lt; 账号登录</span>
        </div>
        <div id="codeBox"></div>
      </div>
    </div>
  </div>
</template>
<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import DragBar from "@/components/dragBar/dragbar.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "loginDialog",
  data() {
    return {
      loginBoxShow: true,
      wxloginBoxShow: false,
      loginType: "first",
      wechatIcon: require("@/assets/icon/loginWechat.png"),
      icon01: require("@/assets/icon/login01.png"),
      icon02: require("@/assets/icon/login02.png"),
      icon03: require("@/assets/icon/login03.png"),
      icon04: require("@/assets/icon/login04.png"),
      icon05: require("@/assets/icon/login05.png"),
      icon06: require("@/assets/icon/login06.png"),
      icon07: require("@/assets/icon/login07.png"),
      icon08: require("@/assets/icon/login08.png"),
      icon09: require("@/assets/icon/login09.png"),
      smsTime: "60",
      loginState: false,
      loginWay: true,
      loginInfo: {
        type: 1,
      },
    };
  },
  components: {
    DragBar,
  },
  computed: {
    ...mapState(["dragState"]),
    codeBtn() {
      return !this.dragState;
    },
  },
  methods: {
    ...mapMutations(["setUserInfo", "changeLogin", "changeDragState"]),
    switchLoginState(tab) {
      if (tab.index == 0) {
        // 短信登录
        this.loginInfo.type = 1;
        this.changeDragState({
          dragState: false,
        });
      } else if (tab.index == 1) {
        // 密码登录
        this.loginInfo.type = 2;
        this.changeDragState({
          dragState: false,
        });
      }
    },
    setWxerwma() {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      const wxElement = document.body.appendChild(s);
      wxElement.onload = function() {
        var obj = new WxLogin({
          id: "codeBox", // 需要显示的容器id
          appid: "wxa8ea41b6aba67301", // 公众号appid
          scope: "snsapi_login", // 网页默认即可
          redirect_uri: "http%3A%2F%2Fwww.51jiansousou.com", // 授权成功后回调的url  51jiansousou.com   zhaomy-frp.4kb.cn
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: "", // 提供"black"、"white"可选。二维码的样式
          href: "", // 外部css文件url，需要https
        });
      };
    },
    //
    changWXLogin() {
      this.loginBoxShow = false;
      this.wxloginBoxShow = true;
      this.setWxerwma();
    },
    backLoginBox() {
      this.wxloginBoxShow = false;
      this.loginBoxShow = true;
    },
    // 登录接口
    goLogin() {
      if (!this.loginInfo.phone) {
        this.$message.warning("登录手机号不能为空！");
        return;
      }
      if (this.loginInfo.type == 1 && !this.loginInfo.code) {
        this.$message.warning("验证码不能为空！");
        return;
      }
      if (this.loginInfo.type == 2 && !this.loginInfo.password) {
        this.$message.warning("登录密码不能为空！");
        return;
      }

      this.$http.post("index/login/login", this.loginInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.$parent.$parent.loginShow = false;
          sessionStorage.setItem("loginOptions", 1);
          sessionStorage.setItem("store", JSON.stringify(this.$store.state));
          // 登陆成功
          this.setUserInfo({
            userInfo: res.data.resultData[0],
          });
          this.changeLogin({ Authorization: res.data.resultData[0].token });
          if (res.data.resultData[0].u_identity == 1) {
            // 判断用户类型
            this.$http.post("talent/index/baseinfo").then((res) => {
              if (res.data.resultCode === 1) {
                if (res.data.resultData.resume == "") {
                  this.$router.push({ path: "/talentpage/firstResume" });
                  return;
                }
                this.$notify.success({
                  message: "登录成功！",
                });
                // setTimeout(() => {
                // this.$parent.$parent.setLoginState();
                // this.$router.go(0);
                // }, 800);
              }
            });
          } else if (
            res.data.resultData[0].u_identity == 2 ||
            res.data.resultData[0].u_identity == 3
          ) {
            console.log(res.data.resultData[0].u_company == 0);
            if (res.data.resultData[0].u_company == 0) {
              this.$router.push({ path: "/comPage/comrealSel" });
              return;
              // setTimeout(() => {
              //   window.location.replace(window.location.href);
              //   this.$parent.$parent.setLoginState();
              //   this.$router.go(0);
              // }, 800);
              this.$notify.success({
                message: "登录成功！",
              });
            } else {
              this.$notify.success({
                message: "登录成功！",
              });
              // setTimeout(() => {
              // window.location.replace(window.location.href);
              // this.$parent.$parent.setLoginState();
              // this.$router.go(0);
              // }, 800);
            }
          }
        }
      });
    },
    // 登录验证码接口
    gainLoginCode() {
      let tel = "+86" + this.loginInfo.phone;
      this.$http.post("index/login/codes", { phone: tel }).then((res) => {
        if (res.data.resultCode === 1) {
          // 验证码发送定时器
          let _this = this;
          let timer = setInterval(function() {
            _this.smsTime--;
            if (_this.smsTime === 0) {
              clearInterval(timer);
              _this.smsTime = 60;
            }
          }, 1000);
          this.$message.success("验证码已发送，请您注意查收！");
        }
      });
    },
    // 前往注册
    goRegist() {
      sessionStorage.setItem("loginOptions", 2);
      let routeUrl = this.$router.resolve({
        name: "Login",
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    this.setWxerwma();
  },
};
</script>
<style scoped>
.positionBox {
  position: absolute;
  right: 0px;
  z-index: 333;
  top: 25px;
}
.loginBox,
.registBox,
.findBox {
  width: 800px;
  height: 500px;
  background: #ffffff;
  /* box-shadow: 0px 14px 24px 9px rgba(185, 185, 185, 0.5); */
  border-radius: 16px;
  position: relative;
  z-index: 2;
}
.registBox {
  height: 560px;
}
.findBox {
  width: 600px;
  height: 460px;
}
.findBox .portrait {
  right: 60px;
}
.findBox .lefthand {
  right: 460px;
}
.findBox .righthand {
  right: 310px;
}
.findBox .positionBox {
  padding-left: 70px;
  padding-right: 70px;
}
.findform {
  background-color: #fff;
  position: relative;
  z-index: 1;
  height: 460px;
  border-radius: 16px;
  padding: 20px 70px;
}
.tabBox,
.tabBox2 {
  position: relative;
  z-index: 3;
  border-radius: 16px;
  background-color: #fff;
}
.tabBox .loginTitle {
  padding-top: 50px;
}
.tabBox2 .loginTitle {
  padding-top: 80px;
}
.imgText {
  padding: 20px;
}
.imgText div {
  float: left;
}
.imgText > div:first-child {
  width: 55px;
}
.imgText .text {
  padding-left: 15px;
}
.padding-20 {
  padding: 20px;
}
.imgText .text span:first-child {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  display: inline-block;
  padding: 9px;
  padding-top: 0;
}
.imgText .text span:last-child {
  font-size: 17px;
  color: #999999;
  line-height: 24px;
  display: inline-block;
  padding-left: 9px;
}
.formBox {
  padding: 30px 30px 0 30px;
}
.loginBtn {
  width: 100%;
  margin: 15px 0 20px;
  border-radius: 32px;
}
.loginBtn:hover {
  box-shadow: 0px 8px 18px 0px #8bb7ff;
}
.loginConfig .el-link {
  float: right;
}
.loginConfig .el-link:last-child {
  margin-right: 63px;
}
.otherLine {
  text-align: center;
  padding: 3px;
  border-bottom: 1px solid #979797;
}
.otherLine span {
  color: #999999;
  background-color: #fff;
  padding: 0 9px;
  position: relative;
  top: 10px;
}
.wxLoginBox {
  background: #fff;
  z-index: 3;
  position: relative;
  border-radius: 20px;
  text-align: center;
  padding-top: 30px;
  height: 470px;
}
.backLoginBox {
  padding: 0px 20px 15px;
  border-bottom: 2px solid #2361ff;
}
.backLoginBox span:hover {
  color: #2573f1;
  cursor: pointer;
}
#codeBox {
  padding-top: 10px;
}
</style>
