<!--
 * @Description: 首页
 * @Author: huacong
 * @Date: 2021-03-25 10:39:12
 * @LastEditTime: 2021-10-27 16:24:26
 * @LastEditors: huacong
-->
<template>
  <div>
    <Nose></Nose>
    <router-view></router-view>
    <Tail></Tail>
  </div>
</template>

<script>
import Nose from "@/components/nose/nose.vue";
import Tail from "@/components/tail/tail.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    Nose,
    Tail,
  },
  data() {
    return {
      code: "",
      state: "",
      ok: false,
    };
  },
  computed: {
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }

      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  watch: {
    userInfo(newVal) {
      console.log(newVal);
      return newVal;
    },
  },
  created() {
    // 获取参数
    this.code = this.$route.query.code;
    this.state = this.$route.query.state;
    // 判断是否有参数
    if (this.code && this.state) {
      this.ok = true;
    } else {
      this.ok = false;
    }
    this.wxloginFn();
  },
  methods: {
    ...mapMutations([
      "changeloginOptions",
      "changeopenid",
      "changeunionid",
      "setUserInfo",
      "changeLogin",
      "changewxName",
    ]),
    wxloginFn() {
      if (this.ok) {
        const params = { code: this.code };
        this.$http.post("index/login/getopenid", params).then((res) => {
          let info = {
            openid: res.data.resultData.openid,
            unionid: res.data.resultData.unionid,
            type: 3,
            login_type: "pc",
          };
          let info1 = {
            acc: res.data.resultData.access_token,
            openid: res.data.resultData.openid,
          };
          this.changeopenid({
            openid: res.data.resultData.openid,
          });
          this.changeunionid({
            unionid: res.data.resultData.unionid,
          });
          let newopenid = res.data.resultData.openid;
          if (this.userInfo.u_nickname == "") {
            this.$http.post("index/login/getwecatinfo", info1).then((res) => {
              if (res.data.resultCode == 1) {
                this.changewxName({
                  wxName: res.data.resultData[0],
                });
              }
            });
          }
          // 已登录关联扫码
          if (this.userInfo.uid != undefined) {
            this.$http
              .post("talent/user/bindwecat", { u_openid: newopenid })
              .then((res) => {
                if (res.data.resultCode === 1) {
                  this.$message.success("绑定成功，返回用户首页！");
                  setTimeout(() => {
                    if (this.userInfo.u_identity == 1) {
                      this.$router.push("/talentPage/index");
                    } else {
                      this.$router.push("/comPage/index");
                    }
                  }, 1000);
                } else {
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 1000);
                }
              });
            return;
          }
          // 未登录时扫码
          this.$http.post("index/login/login", info).then((res) => {
            if (res.data.resultCode == 3) {
              // 状态码3 为首次微信扫码登录
              this.$router.push({ path: "/Login" });
              this.$message.success(
                "关联微信登录，还需要设置您的手机号和密码！"
              );
              sessionStorage.setItem("loginOptions", 3);
            } else if (res.data.resultCode == 1) {
              // 微信扫码登录
              this.setUserInfo({
                userInfo: res.data.resultData[0],
              });
              this.changeLogin({ Authorization: res.data.resultData[0].token });
              if (res.data.resultData[0].u_identity == 1) {
                // 判断用户类型
                this.$http.post("talent/index/baseinfo").then((res) => {
                  if (res.data.resultCode === 1) {
                    if (res.data.resultData.resume == "") {
                      this.$router.push({ path: "/talentpage/firstResume" });
                      return;
                    }
                    this.$notify.success({
                      message: "登录成功！",
                    });
                    // window.location.replace(window.location.href);
                    // this.$router.push({ path: "/Home/index" });
                    // setTimeout(() => {
                    //   this.$router.go(0);
                    // }, 1000);
                  }
                });
              } else if (
                res.data.resultData[0].u_identity == 2 ||
                res.data.resultData[0].u_identity == 3
              ) {
                if (res.data.resultData[0].u_company === 0) {
                  this.$router.push({ path: "/comPage/comrealSel" });
                  return;
                }
                console.log("企业猎头登录，");
                this.$notify.success({
                  message: "登录成功！",
                });
                // window.location.replace(window.location.href);
                // this.$router.push({ path: "/Home/index" });
                // this.$router.go(0);
                // setTimeout(() => {
                // }, 1000);
              } else {
                this.$notify.success({
                  message: "登录成功！",
                });
                // window.location.replace(window.location.href);
                // this.$router.push({ path: "/Home/index" });
                // this.$router.go(0);
                // setTimeout(() => {
                // }, 1000);
              }
              sessionStorage.setItem(
                "store",
                JSON.stringify(this.$store.state)
              );
            }
          });
        });
      }
    },
  },
};
</script>

<style>
body {
  margin: 0;
}
</style>
