<!--
 * @Description: 外部导航
 * @Author: huacong
 * @Date: 2021-03-23 18:16:01
 * @LastEditTime: 2021-12-22 16:20:46
 * @LastEditors: huacong
-->

<template>
  <div>
    <div class="nosebox">
      <section>
        <a class="logo" @click="goHome">
          <img :src="logo" alt="logo" />
        </a>
        <ul class="menu-list">
          <li>
            <router-link :to="{ path: '/Home/index' }">
              首页
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/Home/partTimeIndex' }">
              兼职
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/Home/fullTimeIndex' }">
              全职
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/Home/resumeLibrary' }">
              简历库
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/Home/comList' }">
              企业库
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/Home/aptitudeIndex' }">
              资质街
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ path: '/Home/partCom' }">
              企业兼职
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ path: '/Home/consultingIndex' }">
              建筑资讯
            </router-link>
          </li>
        </ul>
        <span class="loginBtn" v-if="nologinState" @click="goLogin(1)">
          <span @click="goLogin(1)">登录</span>
          <span> / </span>
          <span @click="goLogin(1)">注册</span>
          <!-- <span @click="goLogin(2)">注册</span> -->
        </span>
        <!-- 密码登录时 -->
        <span v-if="loginState1">
          <span class="lander">
            <span @click="goUserInfoPage">{{ getuserInfo.u_phone }}</span>
            <span> | </span>
            <span @click="goLogin(1)">退出登录</span>
          </span>
        </span>
        <!-- F5刷新页面后 -->
        <span v-if="loginState2">
          <span class="lander">
            <span @click="goUserInfoPage">{{ userInfo.u_phone }}</span>
            <span> | </span>
            <span @click="goLogin(1)">退出登录</span>
          </span>
        </span>
        <!-- 微信第一次登录 -->
        <span v-if="loginState3">
          <span class="lander">
            <span @click="goUserInfoPage">{{ getuserInfo.u_phone }}</span>
            <span> | </span>
            <span @click="goLogin(1)">退出登录</span>
          </span>
        </span>
      </section>
    </div>
    <!-- 导航固定填充块 -->
    <div style="height:80px"></div>
    <!-- 侧边-意见反馈-回到顶部 -->
    <div class="rightAisd" v-show="!backShow">
      <!-- <div class="iconBox ercode">
        <img :src="ercode" @click="fankuiBtn()" />
      </div> -->
      <el-popover placement="left-start" trigger="click">
        <div style="width: 355px">
          <div class="tailRight demo-image">
            <div class="block" style="padding-top: 30px;">
              <el-image
                style="width: 100px; height: 100px;padding: 5px;box-sizing: border-box;"
                :src="QRCode01"
              ></el-image>
              <span class="demonstration">{{ QRTitle01 }}</span>
            </div>
            <div class="block">
              <el-image
                style="width: 100px; height: 100px"
                :src="QRCode02"
              ></el-image>
              <span class="demonstration">{{ QRTitle02 }}</span>
            </div>
            <div class="block">
              <el-image
                style="width: 100px; height: 100px"
                :src="QRCode03"
              ></el-image>
              <span class="demonstration">{{ QRTitle03 }}</span>
            </div>
          </div>
        </div>
        <div slot="reference" class="iconBox ercode">
          <img :src="ercode" />
        </div>
      </el-popover>
      <div class="iconBox back">
        <i class="el-icon-chat-line-square" @click="fankuiBtn()"></i>
      </div>
      <div class="iconBox gotop">
        <i class="el-icon-upload2" @click="goTop()"></i>
      </div>
    </div>
    <!-- 登录弹窗 -->
    <el-dialog
      :visible.sync="loginShow"
      title="登录"
      width="840px"
      :close-on-click-modal="false"
      class="notitle"
    >
      <loginDialog></loginDialog>
    </el-dialog>
    <el-dialog
      :visible.sync="backShow"
      title="意见反馈"
      width="640px"
      :close-on-click-modal="false"
    >
      <el-form label-width="75px" v-model="backInfo">
        <el-form-item label="反馈类型">
          <el-radio-group v-model="backInfo.type">
            <el-radio label="1">建议</el-radio>
            <el-radio label="2">BUG反馈</el-radio>
            <el-radio label="3">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="反馈内容">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="backInfo.content"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="backShow = false">取 消</el-button>
        <el-button type="primary" @click="backPost">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { provinceAndCityData } from "element-china-area-data";
import { mapState, mapMutations } from "vuex";
import loginDialog from "@/components/loginDialog/loginDialog.vue";
import $ from "jquery";
export default {
  name: "nose",
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
      ercode: require("@/assets/icon/ercode.png"),
      QRCode01: require("@/assets/img/wxapp.png"),
      QRTitle01: "打开微信小程序",
      QRCode02: require("@/assets/img/appdown.png"),
      QRTitle02: "扫码下载APP",
      QRCode03: require("@/assets/img/erweima01.png"),
      QRTitle03: "扫码关注公众号",
      mapCity: "",
      lander: {},
      ip: "",
      proviceData: provinceAndCityData,
      loginShow: false,
      nologinState: true,
      loginState1: false,
      loginState2: false,
      loginState3: false,
      backShow: false,
      newkey: {},
      backInfo: {},
    };
  },
  components: {
    loginDialog,
  },
  computed: {
    ...mapState(["userInfo", "comId"]),
    getuserInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  watch: {
    userInfo(newVal) {
      this.setLoginState();
      this.loginCheck();
      this.newkey = newVal;
    },
  },
  methods: {
    ...mapMutations(["changeloginOptions", "setUserInfo"]),
    goLogin(state) {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("userInfo");
      this.$router.push({ path: "/Login" });
      sessionStorage.setItem("loginOptions", state);
      // this.changeloginOptions({
      //   loginOptions: state,
      // });
    },
    fankuiBtn() {
      this.backInfo = {};
      this.backShow = true;
    },
    goHome() {
      this.$router.push({ path: "/Home/index" });
    },
    // 返回顶部
    goTop() {
      $("html,body").animate({ scrollTop: 0 }, 800);
    },
    // 意见反馈
    backPost() {
      if (this.getuserInfo.u_phone != undefined) {
        this.backInfo.feeback_uid = this.getuserInfo.uid;
        this.backInfo.feeback_name = this.getuserInfo.u_name;
      } else {
        this.backInfo.feeback_uid = 0;
        this.backInfo.feeback_name = "游客";
      }
      this.$http.post("index/login/feeback", this.backInfo).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success("感谢您的反馈！");
          this.backShow = false;
        }
      });
    },
    // 根据身份跳转详情
    goUserInfoPage() {
      // 密码登录时，使用localStorage数据
      if (Object.keys(this.getuserInfo).length > 0) {
        if (this.getuserInfo.u_identity == 1) {
          this.$http.post("talent/index/baseinfo").then((res) => {
            if (res.data.resultCode === 1) {
              if (res.data.resultData.resume == "") {
                this.$router.push({ path: "/talentpage/firstResume" });
                return;
              }
              this.$router.push({ path: "/talentPage/index" });
            }
          });
        }
        if (
          this.getuserInfo.u_identity == 2 ||
          this.getuserInfo.u_identity == 3
        ) {
          if (this.getuserInfo.u_company == 0 && this.comId == 0) {
            this.$router.push({ path: "/comPage/comrealSel" });
            return;
          }
          this.$router.push({ path: "/comPage/index" });
        }
      } else if (Object.keys(this.userInfo).length > 0) {
        // 微信登录时使用vuex数据
        if (this.userInfo.u_identity == 1) {
          this.$http.post("talent/index/baseinfo").then((res) => {
            if (res.data.resultCode === 1) {
              if (res.data.resultData.resume == "") {
                this.$router.push({ path: "/talentpage/firstResume" });
                return;
              }
              this.$router.push({ path: "/talentPage/index" });
            }
          });
        }
        if (this.userInfo.u_identity == 2 || this.userInfo.u_identity == 3) {
          if (this.userInfo.u_company == 0 && this.comId == 0) {
            this.$router.push({ path: "/comPage/comrealSel" });
            return;
          }
          this.$router.push({ path: "/comPage/index" });
        }
      }
    },
    // 菜单移入效果
    hoverMenu() {
      $(".li-ulList").hover(
        function() {
          $(this)
            .children(".li-menu")
            .show();
        },
        function() {
          $(this)
            .children(".li-menu")
            .hide();
        }
      );
    },
    // 菜单跳转时间
    goPath(src) {
      this.$router.push({ path: src });
      $(".li-menu").hide();
    },
    // 登录状态
    setLoginState() {
      if (
        this.getuserInfo.u_phone === undefined &&
        this.userInfo.u_phone === undefined
      ) {
        this.nologinState = true;
        this.loginState1 = false;
        this.loginState2 = false;
        this.loginState3 = false;
      } else if (this.userInfo.u_phone && this.getuserInfo.u_phone) {
        this.nologinState = false;
        this.loginState1 = true;
        this.loginState2 = false;
        this.loginState3 = false;
      } else if (
        this.getuserInfo.u_phone == undefined &&
        this.userInfo.u_phone
      ) {
        this.nologinState = false;
        this.loginState1 = false;
        this.loginState2 = true;
        this.loginState3 = false;
      } else if (
        this.getuserInfo.u_phone &&
        this.userInfo.u_phone == undefined
      ) {
        this.nologinState = false;
        this.loginState1 = false;
        this.loginState2 = false;
        this.loginState3 = true;
      }
    },
    // 检测登录
    loginCheck() {
      let s = 60 * 1000 * 3; // 2分钟询问登录
      // let s = 3 * 1000 * 3; // 9s询问登录
      if (this.userInfo.u_login_time != undefined) {
        let loginTime = this.userInfo.u_login_time; // 登录时间
        // let timeLimit = 30; // 半分钟登录时限
        let timeLimit = 1 * 60 * 60 * 6; // 6小时登录时限
        let nowTime = Math.round(new Date() / 1000);
        if (loginTime + timeLimit < nowTime) {
          localStorage.removeItem("Authorization");
          localStorage.removeItem("userInfo");
          this.loginState1 = false;
          this.loginState2 = false;
          this.loginState3 = false;
          this.nologinState = true;
          this.$message.warning("您的登录时间已过期，请重新登录！");
        }
        let loginListen = setTimeout(() => {
          let nowTime1 = Math.round(new Date() / 1000);
          if (loginTime + timeLimit < nowTime1) {
            localStorage.removeItem("Authorization");
            localStorage.removeItem("userInfo");
            this.nologinState = true;
            this.loginState1 = false;
            this.loginState2 = false;
            this.loginState3 = false;
            this.loginShow = true;
            this.$message.warning("您的登录时间已过期，请重新登录！");
            if (this.getuserInfo.u_login_time == undefined) {
              window.clearInterval(loginListen);
            }
          }
        }, s);
      } else if (this.getuserInfo.u_login_time != undefined) {
        let loginTime = this.getuserInfo.u_login_time; // 登录时间
        // let timeLimit = 30; // 半分钟登录时限
        let timeLimit = 1 * 60 * 60 * 6; // 6小时登录时限
        let nowTime = Math.round(new Date() / 1000);
        if (loginTime + timeLimit < nowTime) {
          localStorage.removeItem("Authorization");
          localStorage.removeItem("userInfo");
          this.nologinState = true;
          this.loginState1 = false;
          this.loginState2 = false;
          this.loginState3 = false;
          this.$message.warning("您的登录时间已过期，请重新登录！");
        }
        let loginListen = setTimeout(() => {
          let nowTime1 = Math.round(new Date() / 1000);
          if (loginTime + timeLimit < nowTime1) {
            localStorage.removeItem("Authorization");
            localStorage.removeItem("userInfo");
            this.nologinState = true;
            this.loginState1 = false;
            this.loginState2 = false;
            this.loginState3 = false;
            this.loginShow = true;
            // this.$message.warning("您的登录时间已过期，请重新登录4！");
            if (this.getuserInfo.u_login_time == undefined) {
              window.clearInterval(loginListen);
            }
          }
        }, s);
      } else {
        this.nologinState = true;
        this.loginState1 = false;
        this.loginState2 = false;
        this.loginState3 = false;
        let nologinListen = setInterval(() => {
          if (
            this.getuserInfo.u_login_time != undefined ||
            this.userInfo.u_login_time != undefined
          ) {
            window.clearInterval(nologinListen);
            return;
          }
          this.loginShow = true;
        }, s);
      }
    },
  },
  mounted() {
    this.setLoginState();
    this.loginCheck();
  },
};
</script>
<style scoped>
.nosebox {
  min-width: 1240px;
  background-color: #131d28;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
section .logo {
  margin-top: 5px;
}
section {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.city {
  width: 147px;
  display: inline-block;
  margin: 0px 25px;
}
.city i {
  color: aliceblue;
  font-size: 16px;
}
.city span {
  color: #fff;
  font-size: 14px;
  padding: 0 3px;
}
.menu-list {
  display: inline-block;
  list-style: none;
  margin-left: auto;
}
.menu-list > li {
  display: block;
  float: left;
  color: #fff;
  cursor: pointer;
  padding: 10px 0;
}
.menu-list li a {
  color: #fff;
  padding: 10px 23px;
}
.menu-list li:hover {
  opacity: 0.6;
}
.loginBtn {
  float: right;
  padding: 12px 20px;
  background: #2573f1;
  color: #fff;
  border-radius: 50px;
  margin-left: auto;
}
.loginBtn span,
.lander span {
  cursor: pointer;
}
.lander {
  margin-left: auto;
  color: #fff;
}
.citySpan {
  margin: 3px 4px;
  display: inline-block;
}
.citySpan:hover {
  color: #2573f1;
  cursor: pointer;
}
.cityNow {
  width: 53px;
  display: inline-block;
  position: relative;
  top: 4px;
}
.rightAisd {
  position: fixed;
  top: 55vh;
  right: 0;
  z-index: 99;
}
.iconBox:first-child {
  padding-top: 16px;
  padding-bottom: 12px;
}
.iconBox {
  padding: 14px;
  background: #fff;
  margin-top: 10px;
  border-radius: 50%;
}

.iconBox:hover i {
  color: #2573f1;
}
.iconBox i {
  font-size: 26px;
  color: #6b6b6b;
}
.iconBox.ercode {
  filter: Gray;
  -webkit-filter: grayscale(100%);
}
.iconBox.ercode:hvoer {
  filter: Gray;
  -webkit-filter: grayscale(0%);
}
.iconBox:hover {
  box-shadow: 0px 0px 8px 0px rgba(200, 200, 200, 0.5);
  -webkit-filter: grayscale(0%);
}
.tailRight {
  width: 400px;
  float: left;
}
.block {
  padding: 30px 8px 0px;
  text-align: center;
  border-right: 1px solid #eff2f6;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
</style>
